import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fc69e95c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "btn",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.wechatLogin && $options.wechatLogin(...args))
  }, "点击授权")]);
}