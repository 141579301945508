//引入js
import { loginByOpenid } from '@/api/user';
export default {
  components: {},
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_API,
      appId: '',
      url: ''
    };
  },
  created: function () {
    // 获取appid
    this.appId = this.$store.state.globalData.wechatConfig.appId;
    // 获取url地址
    this.url = window.location.href;
    // 获取code
    let code = this.getQueryString('code');
    // code不存在则停止登录
    if (!code) {
      return;
    }
    // 有code，说明用户手动进行了微信授权，接下来ajax获取用户信息并实现注册登录
    this.login(code);
  },
  beforeMount: function () {},
  mounted: function () {},
  methods: {
    // 获取地址栏参数
    getQueryString(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      let url = window.location.search.substr(1).match(reg);
      return url ? decodeURI(url[2]) : null;
    },
    wechatLogin: function () {
      window.location = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + encodeURIComponent(this.url) + '&response_type=code&scope=snsapi_userinfo#wechat_redirect';
    },
    login: async function (code) {
      // code进行登录或者注册
      const ajaxData = {};
      ajaxData.code = code;
      const resultData = await loginByOpenid(ajaxData);
      // 登录注册成功
      if (resultData.code == 200) {
        // 准备过期时间
        let expireTime = Date.parse(new Date()) / 1000 + 72000; //20小时后过期
        // 同步更新store
        this.$store.commit('setIdentity', 1);
        this.$store.commit('setToken', resultData.data);
        this.$store.commit('setExpireTime', expireTime);
        // 存储到localStorage
        localStorage.setItem('identity', 1);
        localStorage.setItem('token', resultData.data);
        localStorage.setItem('expireTime', expireTime);

        // 如果记录了需要重定向的页面，则需要跳转指定页面
        let redirectUrl = localStorage.getItem('redirectUrl');
        if (redirectUrl) {
          window.location = 'https://calt.ltxzfcg.gov.cn/' + redirectUrl;
        }
      }
    }
  }
};